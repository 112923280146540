<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxCellDate, VxCellDrop, VxCellLink, VxCellPlayerAvatar, VxTable } from '@/components/table'
import { exchanges, passDataToResource } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { moneyFormat } from '@/services/utils'
import { buildRoute, path } from '@/router'

export default {
  name: 'Exchanges',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar,
    VxCellDrop,
    VxCellLink
  },
  setup () {
    const resource = passDataToResource(exchanges.getAll, {
      requestParams: {
        params: { include: 'exchangedDrop,obtainedDrops,player,compensation' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'exchanged_drop',
        component: VxCellDrop
      },
      {
        key: 'exchanged_drop.main_price',
        label: 'Price',
        tdClass: ['text-nowrap'],
        formatter: (value) => moneyFormat(value)
      },
      {
        key: 'obtained_drops',
        label: 'Items After Exchange',
        component: VxCellDrop
      },
      {
        key: 'total_obtained_drops',
        label: 'Total prices',
        formatter: (value, key, item) => {
          const total = item?.obtained_drops.reduce((acc, prev) => (acc + prev.main_price), 0)
          return moneyFormat(total)
        }
      },
      {
        key: 'compensation',
        component: VxCellLink,
        tdAttr: (compensation) => {
          if (!compensation) return
          const { main_delta, id } = compensation

          return {
            label: `${moneyFormat(main_delta)}`,
            href: buildRoute(path.balanceChanges, { query: { id } })
          }
        }
      },
      {
        key: 'created_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'exchanged_drop_id',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
